@import "../../../../styles/common";

.titleInput {
  width: 600px;
}

.rowContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.spaceBetweenRow {
  width: 650px !important;
  justify-content: space-between;
}

.titleText {
  font-size: 14px;
  font-weight: 600;
  color: $darkGray;
}

.categoryContainer {
  width: 350px;
  justify-content: space-between;
}

.categoryCountText {
  margin-left: 8px;
  color: $blue;
  font-weight: 700;
}

.marginRight {
  margin-right: 8px;
}

.marginLeft {
  margin-left: 8px;
}

.marginTop {
  margin-top: 16px;
}

.error {
  color: $red;
}

.errorInputContainer {
  border-color: $red;
}

.errorInputContainer:focus {
  border-color: $red;
}
