@import "../../styles/colors";
@import "../../styles/vars";

.fieldList {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: stretch;

  .field {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: $primaryPadding;
    }

    .label {
      margin-bottom: 7px;
      font-size: $largeSizeText;
      font-weight: $semiBold;
      font-family: $secondary-font-color;
    }

    .caption {
      margin-top: 7px;
      font-size: $normalSizeText;
      font-family: $secondary-font-color;
    }

    .component {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .hidden {
    display: none;
  }
}
