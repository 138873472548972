@import "../../../../styles/common";
@import "../../../../styles/colors";

.block {
  margin-top: 20px;
  margin-bottom: 20px;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.titleText {
  font-size: 16px;
  font-weight: 600;
  color: $darkGray;
}

.emptyText {
  margin-top: 24px;
  font-size: 14px;
  font-weight: 400;
  color: $black;
}

.questionContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.questionCountText {
  margin-left: 8px;
  color: $blue;
  font-weight: 700;
}

.questionTextContainer {
  font-size: 14px;
  font-weight: 400;
}

.questionTextContainer:hover {
  cursor: pointer;
}

.questionTextContainerDisabled {
  font-size: 14px;
  font-weight: 400;
}

.filtersContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.selectMargin {
  margin-left: 16px;
  max-width: 300px;
}

.questionContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.marginTop {
  margin-top: 20px;
}

.checkbox {
  margin-right: 16px;
  span {
    border-color: $lightBlue !important;
  }
}

.tablePadding {
  table {
    td {
      padding: 16px;
    }
  }
}

.firstColumn80 {
  table {
    th:first-child {
      width: 80%;
    }
    td {
      padding: 16px;
    }
  }
}

.firstColumn40 {
  table {
    th:first-child {
      width: 40%;
    }
    td {
      padding: 16px;
    }
  }
}
