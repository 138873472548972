@import "../../../../styles/common";

.lineCategoryText {
  font-size: 14px;
  font-weight: 400;

  margin-top: -5px;
  padding-bottom: 15px;
  border-bottom: solid 2px $primary-border-color;
}

.titleText {
  font-size: 18px;
  font-weight: 700;

  margin-top: 26px;
}

.typeText {
  font-size: 14px;
  margin-top: 16px;
  color: $blue;
}

.answersContainer {
  margin-top: 26px;
}

.answerItemContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
}

.answerTextContainerSelector {
  font-size: 16px;
  font-weight: 400;

  width: 250px;
}

.answerTextContainerDisabled {
  font-size: 16px;
  font-weight: 400;
}

.answerTextContainer {
  font-size: 16px;
  font-weight: 400;
}

.answerTextContainer:hover {
  cursor: pointer;
}
.checkbox {
  margin-right: 16px;
  span {
    border-color: $lightBlue !important;
  }
}

.selectMargin {
  margin-left: 16px;
  max-width: 300px;
}

.correctAnswer {
  font-weight: 600;
  color: $darkBlue;
  margin-right: 8px;
}

.correctAnswerSelector {
  margin-left: 8px;
}
