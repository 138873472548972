@import "../../styles/vars";

.container {
  $checkBoxSize: 20px;
  $checkedBoxSize: 16px;
  position: relative;
  padding-left: $checkBoxSize;
  margin-bottom: $checkBoxSize;
  cursor: pointer;
  font-size: 17px;
  user-select: none;

  &.disabled {
    .checkMark {
      opacity: 0.8;
      cursor: default;
    }
  }

  .checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    top: 0;
    left: 0;

    &:checked ~ .checkMark {
      background-color: $secondary-font-color;
    }
    &:checked ~ .checkMark:after {
      display: block;
    }
  }

  .checkMark {
    border-radius: 100px;
    position: absolute;
    top: 0;
    left: 0;
    height: $checkBoxSize;
    width: $checkBoxSize;
    background-color: white;
    border: 2px solid #d5d5d5;
    box-sizing: border-box;

    &:after {
      content: "";
      position: absolute;
      display: none;
      border-radius: 100px;

      width: $checkedBoxSize;
      height: $checkedBoxSize;
      border: solid white;
      border-width: 2px;
    }
  }
}
