@import "../../styles/common";

.block {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.blockAlingRight {
  justify-content: right;
}

.blockSpaceBetween {
  justify-content: space-between;
}

.checkBoxContainer {
  margin-left: 25px;
}

.headerUserInfo {
  font-weight: 400;
  font-size: 13px;
  color: $color-new-primary-blue;
}

.emptyListTitle {
  margin: 16px 13px;
}

.selectorHeader {
  padding: 0;
}

.pressableText:hover {
  cursor: pointer;
}

.blueText {
  color: $secondary-button-color;
}
.statusText {
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
}

.error {
  color: $red;
}

.errorInputContainer {
  border-color: $red;
}

.errorInputContainer:focus {
  border-color: $red;
}

.marginRight {
  margin-right: 8px;
}

.marginLeft {
  margin-left: 8px;
}

.addButton {
  margin-right: 16px;
}

.userFormInput {
  width: 300px;
}
