@import "../../styles/vars";
@import "../../styles/colors";

.container {
  text-align: center;
  border-width: 0;
  font-family: $secondary-font-family;
  font-size: 18px;
  padding: 7px 9px;
  line-height: 16px;
  border-radius: $primary-border-radius;
  background: $primary-background-color;
  color: $newRed;
  font-weight: bold;
}
