@import "../../../styles/colors";
@import "../../../styles/vars";

/*TODO move this color to theme styles*/
$background: #f2f2f4;
$accent: #ed6463;
$defaultColor: #000;
$iconGray: #c2cfe0;

.tab {
  font-size: $largeSizeText;
  font-weight: bold;
  line-height: 50px;
  color: $newPrimary;
  height: 50px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;

  .activeLine {
    position: absolute;
    left: 16px;
    height: 44px;
    width: 3px;
    background-color: $accent;
    border-radius: 10px;
  }

  .icon {
    margin-right: 10px;
    width: 21px;
    height: 21px;
    path {
      &[fill] {
        fill: $iconGray;
      }
      &[stroke] {
        stroke: $iconGray;
      }
    }
  }

  &:hover {
    background: $lightGray;
  }

  &.active {
    background: $background;

    .icon path {
      &[fill] {
        fill: $accent;
      }

      &[stroke] {
        stroke: $accent;
      }
    }
  }

  &.short {
    .icon {
      margin-right: 0;
    }
  }
}
