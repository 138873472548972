$white: #fff;
$red: rgb(235, 49, 65);
$green: rgb(22, 170, 9);
$inputGreen: #32aa09;
$schemeGreen: #107308;
$lightBlue: #86cffb;
$paleBlue: #f8fafb;
$blue: #188dd2;
$darkBlue: #126696;
$lightGray: #f5f6f8;
$borderGray: #8c8c8c;
$gray: #b8b8b8;
$selectRed: #d6131b;
$formGray: #8c8c8c;
$darkGray: #5f5f5f;
$black: #000000;
$fadedBlack: rgba($black, 0.7);
$newRed: #ff5760;
$newPrimary: #092366;
$newSuccess: #50e2d3;
$blackText: #202020;

$primaryBtn: #0460a9;
