@import "../../../styles/common";
@import "../../../styles/vars";
@import "../../../styles/colors";

.timerText {
  font-family: $secondary-font-family;
  font-size: 18px;
  line-height: 16px;
  color: $newRed;
  font-weight: bold;
}

.hidden {
  display: none !important;
}

.wideTitle {
  width: 700px;
}

.narrowTitle {
  width: 200px;
}

.dateTitle {
  width: 120px;
}

.idTitle {
  width: 50px;
}

.dialogTitleContainer {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.dialogTitleText {
  font-size: 20px;
  margin-right: 32px;
  font-weight: 700;
  color: $newPrimary;
}

.dialogQuestionsCountText {
  font-size: 16px;
  margin-right: 8px;
  font-weight: 600;
}
.dialogQuestionsCountTextColored {
  font-size: 16px;
  color: $newPrimary;
  font-weight: 600;
}

.titleResultContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.titleResultTest {
  font-size: 100px;
  color: $newRed;
}

.succesPercentTextColor {
  color: $newSuccess;
}

.subtitleResultTest {
  font-size: 14px;
  font-weight: 700;
  color: $newPrimary;
}

.subtitleResultTestPercent {
  margin-left: 4px;
}

.summaryResultTestContainer {
  margin-top: 64px;
  flex-direction: column;
  align-items: flex-start;
}

.summaryResultTestTitle {
  font-size: 16px;
  font-weight: 700;
  color: $black;
}

.summaryResultTest {
  margin-top: 12px;
  font-size: 14px;
  color: $blue;
}

.centerText {
  text-align: center;
}

.noMargin {
  margin-right: 0 !important;
}

.pressableText {
  font-weight: 600;
}
.pressableText:hover {
  cursor: pointer;
}

.startTestButtonText {
  color: $primary-button-color;
}

.rightAnswesTable {
  margin-top: 26px;
}

.questionContainer {
  height: 100%;
  border-bottom: solid 2px $primary-border-color;
}
