@import "../../../../styles/common";
@import "../../../../styles/vars";

.filter {
  height: 35px;
  padding: 0 5px;
  border-radius: 5px;
  border: none;
  font-size: $largeSizeText;
  font-family: $primary-font-family;
  line-height: 35px;
  color: $secondary-font-color;
  margin-left: 16px;
  max-width: 300px;
  opacity: 0.7;
}

.titleContainer {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.usersCountText {
  margin-left: 8px;
  color: $blue;
  font-weight: 700;
}

.titleText {
  font-size: 16px;
  font-weight: 600;
  color: $darkGray;
}

.emptyText {
  margin-top: 24px;
  font-size: 14px;
  font-weight: 400;
  color: $black;
}

.userSelectAllContainer {
  margin-top: 36px !important;
}

.userContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.userTextContainer {
  font-size: 14px;
  font-weight: 400;
}
.userTextContainer:hover {
  cursor: pointer;
}

.userTextContainerDisabled {
  font-size: 14px;
  font-weight: 400;
}

.checkbox {
  margin-right: 16px;
  span {
    border-color: $lightBlue !important;
  }
}

.filtersContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.selectMargin {
  margin-left: 16px;
  max-width: 300px;
}
