@import "../../../styles/vars";

.dateInput {
  resize: none;
  border-radius: 5px;
  // border: 1px solid $secondary-border-color;
  font-size: $largeSizeText;
  // font-family: $secondary-font-color;
  min-width: 150px;
}
