@import "../../../styles/common";
@import "../index.module.scss";

.hidden {
  display: none !important;
}

.createTestDialogBody {
  height: 80%;
}

.wideTitle {
  width: 700px;
}

.centerText {
  text-align: center;
}

.noMargin {
  margin-right: 0 !important;
}

.pressableText:hover {
  cursor: pointer;
}

.blueText {
  color: $secondary-button-color;
}

.greenText {
  color: $inputGreen;
}

.statusText {
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
}

.statusTextLight {
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
}
