@import "../../../styles/colors";
@import "../../../styles/vars";

$borderColor: #ebebeb;
$titleColor: #092367;

.tabPanel {
  width: 100%;
  position: absolute;
  background: $white;
  z-index: 1;
  height: 100%;
  border-left: 5px solid $borderColor;

  .tabTitle {
    position: relative;
    padding: 0 $primaryPadding 0 33px;
    font-weight: bold;
    height: $titleHeight;
    font-size: 22px;
    color: $titleColor;
    display: flex;
    align-items: flex-end;
  }

  .tabBodyOuter {
    position: relative;
    height: calc(100% - #{$titleHeight});

    .tabBodyInner {
      padding: 8px $primaryPadding $primaryPadding $primaryPadding;
      height: 100%;
      overflow: auto;
    }
  }
}

.hidden {
  z-index: 0;
}
